<template>
  <div>
    <datatable :data="payments" :columns="columns" :limit="8" />
  </div>
</template>
<script>
export default {
  props: {
    staff: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      payments: [],
      columns: [
        {
          name: "amount",
          th: "Amount",
          render: (transaction, amount) =>
            `₦ ${this.$options.filters.currency(amount)}`,
        },
        {
          name: "payment_method",
          th: "Payment Method",
        },
        {
          name: "created_at",
          th: "Date",
          render: (transaction, created_at) =>
            this.$options.filters.dateFormat(created_at, "D, dd M Y h:ia"),
        },
        {
          name: "status",
          th: "Status",
          render: (transaction, status) => {
            switch (status) {
              case "approve":
                return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Approved
									</div>`;
              case "decline":
                return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-red-100 text-xs px-3 py-2 inline-block">
										Declined
                                    </div>`;
              case "pending":                                    
              default:
                return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
										Pending
									</div>`;
            }
          },
        },
      ],
    };
  },
  beforeMount() {
    this.getUserPayments();
  },
  methods: {
    async getUserPayments() {
      this.loading = true;
      await this.$get({
        url: `${this.$baseurl}/admin/personal/cards/${this.staff.id}/payments`,
        headers: this.headers,
        success: (response) => {
          this.payments = response.data.payments;
        },
        error: () => {
        },
      });
      this.loading = false;
    },
  },
};
</script>
